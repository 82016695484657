import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/styles/style.min.css";
import Loader from "./components/UI/Loader";
import socket from "./config/socket";
import { generateKey, languageCode } from "./helpers/all";
import { getImageURL } from "./helpers/image";
import AppRouter from "./routes/AppRouter";
import { checkAuth, logout } from "./services/auth";
import { getBrandOptions } from "./services/module";
import { setAffiliates } from "./store/reducers/affiliateSlice";
import { setAuth, setUser } from "./store/reducers/authSlice";
import { setBrands } from "./store/reducers/brandSlice";
import {
  updateApiId,
  updateBrand,
  updateIp,
  updateSource,
} from "./store/reducers/settingsSlice";
import { setStatuses } from "./store/reducers/statusSlice";

const App = () => {
  const { i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const brand = useSelector((state) => state.brand.active);
  const affiliateActive = useSelector((state) => state.affiliate.active);
  const optionsBrand = useSelector((state) => state.settings.brand);
  const apiId = useSelector((state) => state.settings?.apiId);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(auth.isAuth);

  const updateFavicon = useCallback((options) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = getImageURL(options);
  }, []);

  useLayoutEffect(() => {
    (async () => {
      try {
        let apiIdCode = apiId;
        if (!apiIdCode || apiIdCode.length === 0) {
          apiIdCode = generateKey(100);
          dispatch(updateApiId(apiIdCode));
        }
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get("source");

        if (source) {
          const [value, sourceValue] = source.split("=");
          value && dispatch(updateSource(value));
        }
        
        await getBrandOptions()
          .then(async (res) => {
            if (res?.options) {
              dispatch(updateBrand(res.options));
              if (res.options.favicon) {
                updateFavicon({
                  path: res.options.favicon,
                  type: "all/brand/favicon",
                  size: "full",
                });
              }
            }
          })
          .catch(
            () =>
              optionsBrand?.favicon &&
              updateFavicon({
                path: optionsBrand.favicon,
                type: "all/brand/favicon",
                size: "full",
              })
          );

        await axios
          .get("https://ip.yooapp.ru")
          .then(({ data }) => data?.ip && dispatch(updateIp(data.ip)));

        if (auth?.user?.id) {
          await checkAuth()
            .then((data) => {
              data.member && dispatch(setUser(data.member));
              data.member && dispatch(setAuth(true));
              if (data?.member?.lang) {
                i18n.changeLanguage(languageCode(data.member.lang));
                moment.locale(languageCode(data.member.lang));
              }
              dispatch(setBrands(data.brands));
              var affiliates = [];
              var affiliatesActive = null;
              if (
                data?.member?.affiliateId === null ||
                !data?.member?.affiliateId
              ) {
                affiliates = [{ id: null, title: "Все" }, ...data.affiliates];

                if (!affiliateActive) {
                  affiliatesActive = affiliates[0];
                }
              } else {
                affiliates = data.affiliates.filter(
                  (e) => e.id === data.member.affiliateId
                );
                if (affiliates?.length > 0) {
                  affiliates[0].main = true;
                  if (!affiliateActive) {
                    affiliatesActive = affiliates[0];
                  }
                }
              }

              dispatch(
                setAffiliates({ active: affiliatesActive, data: affiliates })
              );
              dispatch(setStatuses(data.statuses));

              if (data?.brands.length > 0) {
                let brandActive = data.brands.find((e) => e.main);
                if (brandActive?.id && data?.member?.id) {
                  socket.io.opts.query = {
                    brandId: brandActive.id,
                    memberId: data.member.id,
                  };
                  socket.connect();
                  socket.emit("member/add", {
                    memberId: data.member.id,
                  });
                }
              }
            })
            .catch((err) => err?.response?.status === 404 && dispatch(logout()))
            .finally(() => setLoading(false));
        }else{
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      }

      return () => socket.off("connect");
    })();
  }, [brand, apiId]);

  if (loading) {
    return <Loader full />;
  }

  return <AppRouter />;
};
export default App;
