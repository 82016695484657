import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import {
  Badge,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Offcanvas,
} from "react-bootstrap";
import {
  IoInformationCircleOutline,
  IoRefreshOutline,
  IoSettingsOutline,
  IoTerminalOutline,
} from "react-icons/io5";
import { getStatistics } from "../../../services/statistic";
import {
  getEprCategory,
  getEprDiscounts,
  getEprModifier,
  getEprOrganizations,
  getEprProduct,
  getEprTerminal,
  getEprTypesDelivery,
  getEprTypesPayment,
  getEprMenu,
  getEprAll,
  getEprCoupons,
  getEprTable,
  getEprStopList,
  getEprPromos,
} from "../../../services/sync";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "../../../components/UI/Select";
import socket from "../../../config/socket";
import { NotificationManager } from "react-notifications";
import moment from "moment";

const initalData = {
  type: "office",
  login: null,
  payment: {
    card: null,
    cash: null,
    point: null,
    online: null,
  },
  delivery: {
    hall: null,
    pickup: null,
    delivery: null,
  },
  terminals: [],
  typesPayment: [],
  organizations: [],
  typesDelivery: [
    {
      items: [],
      organizationId: "",
    },
  ],
};

const openWindow = ({ title, data, header, body, json = true }) => {
  {
    const newWindow = window.open("", "_blank");
    if (newWindow) {
      newWindow.document.write(
        `<html><head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${title ?? "Логи"}</title>
    <style>
        .code {
            white-space: break;
        }
        .string { color: green; white-space: break-spaces; }
        .number { color: darkorange; }
        .boolean { color: blue; }
        .null { color: magenta; }
        .key { color: red; }
    </style>
    </head><body>${body}</body>
  <script>
    function output(str, id) {
      document.getElementById(id).innerHTML = str;
    }
    function syntaxHighlight(json) {
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
          var cls = 'number';
          if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                  cls = 'key';
              } else {
                  cls = 'string';
              }
          } else if (/true|false/.test(match)) {
              cls = 'boolean';
          } else if (/null/.test(match)) {
              cls = 'null';
          }
          return '<span class="' + cls + '">' + match + '</span>';
      });
  }
  var data = JSON.stringify(${
    json ? JSON.stringify(data) : data
  }, undefined, 4);
  var header = ${
    header
      ? `JSON.stringify(${
          json ? JSON.stringify(header) : header
        }, undefined, 4)`
      : "null"
  };
  ${header ? `output(syntaxHighlight(header), 'header');` : ""}
  output(syntaxHighlight(data), 'data');
    </script>
  </html>`
      );
      newWindow.document.close();
    }
  }
};

const IikoForm = ({ data, onSubmit, onUpdate, setInitalData }) => {
  const brand = useSelector((state) => state?.brand?.active);
  const [settings, setSettings] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data ?? initalData,
  });

  const form = useWatch({ control });

  const [all, setAll] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    categories: 0,
    products: 0,
    modifiers: 0,
    tables: 0,
    stoplist: 0,

    organizations: 0,
    discounts: 0,
    typesDelivery: 0,
    typesPayment: 0,
    terminal: 0,
  });

  const updateStatistics = () => {
    getStatistics({
      sync: true,
      category: true,
      table: true,
      product: true,
      modifier: true,
    })
      .then((res) => {
        onUpdate();
        setStatistic({ ...statistic, ...res, loading: false });
      })
      .catch(() => setStatistic({ ...statistic, loading: false }));
  };

  const clickGetErpProduct = useCallback(() => {
    getEprProduct(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpModifier = useCallback(() => {
    getEprModifier(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpStopList = useCallback(() => {
    getEprStopList(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTable = useCallback(() => {
    getEprTable(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpCategory = useCallback(() => {
    getEprCategory(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpOrganization = useCallback(() => {
    getEprOrganizations(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpCoupon = useCallback(() => {
    getEprCoupons(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpDiscount = useCallback(() => {
    getEprDiscounts(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpPromo = useCallback(() => {
    getEprPromos(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTypesDelivery = useCallback(() => {
    getEprTypesDelivery(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTypesPayment = useCallback(() => {
    getEprTypesPayment(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTerminal = useCallback(() => {
    getEprTerminal(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpMenu = useCallback(() => {
    getEprMenu(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpAll = useCallback(() => {
    setAll(true);
    getEprAll(form)
      .catch((error) => {
        if (
          error?.response?.data?.error &&
          error?.response?.data?.error == typeof "string"
        ) {
          NotificationManager.error(error.response.data.error);
        }
      })
      .finally(() => setAll(false));
  }, [form]);

  useEffect(() => {
    setInitalData(initalData);
    updateStatistics();

    socket.on("module/iiko", (data) => {
      if (data) {
        setValue("relationModule.options.status", data);
        if (
          data?.category?.status == "end" ||
          data?.product?.status == "end" ||
          data?.modifier?.status == "end" ||
          data?.organization?.status == "end" ||
          data?.terminal?.status == "end" ||
          data?.discount?.status == "end" ||
          data?.menu?.status == "end" ||
          data?.delivery?.status == "end" ||
          data?.payment?.status == "end"
        ) {
          updateStatistics();
        }
      }
    });

    return () => {
      socket.off("module/iiko");
    };
  }, [brand]);

  useEffect(() => {
    data && reset(data);
  }, [data]);

  return (
    <>
      <Card body className="mb-5">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-4">Настройки</h5>
          </div>
          <div>
            {!form.relationModule?.options?.type ||
            form.relationModule?.options?.type === "" ? (
              <Badge bg="danger">Back Office</Badge>
            ) : (
              <Badge>Web</Badge>
            )}
          </div>
        </div>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Логин"
                placeholder="Введите логин"
                name="relationModule.options.login"
                errors={errors}
                defaultValue={form?.relationModule?.options?.login}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
              {form?.relationModule?.options?.error ? (
                <p className="text-danger fs-07 mt-1">
                  {typeof form.relationModule.options.error === "object"
                    ? JSON.parse(form.relationModule.options.error)
                    : String(form.relationModule.options.error)}
                </p>
              ) : (
                <p className="text-muted fs-07 mt-1">
                  Спросите специалиста который подключал вам систему
                </p>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Select
                label="Тип интеграции"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.type", e.value)
                }
                value={form?.relationModule?.options?.type}
                data={[
                  { title: "Back Office", value: null },
                  { title: "Web", value: "web" },
                ]}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Input
                label="Идентификатор доставки"
                placeholder="Введите id"
                name="relationModule.options.deliveryApiId"
                errors={errors}
                defaultValue={form?.relationModule?.options?.deliveryApiId}
                register={register}
              />
              <small className="text-muted fs-08">
                Создайте товар в iiko с изменяемой суммой для доставки и укажите
                идентификатор.
              </small>
            </div>
          </Col>
          <Col lg={12} className="d-flex flex-wrap flex-row mt-2">
            <Form.Check className="mb-3 me-4">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.callCenter"
                id="callCenter"
                defaultChecked={form?.relationModule?.options?.callCenter}
                {...register("relationModule.options.callCenter")}
              />
              <Form.Check.Label htmlFor="callCenter" className="ms-2">
                Получать заказы через колл-центр
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="mb-3 me-4">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.required"
                id="required"
                defaultChecked={form?.relationModule?.options?.required}
                {...register("relationModule.options.required")}
              />
              <Form.Check.Label htmlFor="required" className="ms-2">
                Обязательные цены по филиалам
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="mb-4 me-4">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.address"
                id="address"
                defaultChecked={form?.relationModule?.options?.address}
                {...register("relationModule.options.address")}
              />
              <Form.Check.Label htmlFor="address" className="ms-2">
                Необязательные адреса в заказе
              </Form.Check.Label>
            </Form.Check>
            <Form.Check className="mb-4">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.synsCart"
                id="synsCart"
                defaultChecked={form?.relationModule?.options?.synsCart}
                {...register("relationModule.options.synsCart")}
              />
              <Form.Check.Label htmlFor="synsCart" className="ms-2">
                Выключить синхронизацию корзины
              </Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
        <h5 className="mt-3 mb-4">Типы доставок</h5>
        <Row className="gx-0">
          <Col xxl={4} md={6}>
            <div className="mb-3">
              <Select
                label="Доставка"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.delivery.delivery", e.value)
                }
                value={
                  form?.relationModule?.options?.delivery?.delivery ?? null
                }
                data={
                  form?.relationModule?.options?.typesDelivery &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesDelivery[0]?.items.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col xxl={4} md={6}>
            <div className="mb-3">
              <Select
                label="Самовывоз"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.delivery.pickup", e.value)
                }
                value={form?.relationModule?.options?.delivery?.pickup ?? null}
                data={
                  form?.relationModule?.options?.typesDelivery &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesDelivery[0]?.items.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col xxl={4} md={6}>
            <div className="mb-3">
              <Select
                label="В зале"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) =>
                  setValue("relationModule.options.delivery.hall", e.value)
                }
                value={form?.relationModule?.options?.delivery?.hall ?? null}
                data={
                  form?.relationModule?.options?.typesDelivery &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesDelivery[0]?.items.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
        </Row>
        <h5 className="mt-3 mb-4">Типы оплат</h5>
        <Row className="gx-0">
          <Col xxl={3} md={6}>
            <div className="mb-3">
              <Select
                noSelect
                label="Картой при получении"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );
                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.card", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.card", null);
                  }
                }}
                value={form?.relationModule?.options?.payment?.card?.id}
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col xxl={3} md={6}>
            <div className="mb-3">
              <Select
                label="Наличными"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );
                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.cash", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.cash", null);
                  }
                }}
                value={form?.relationModule?.options?.payment?.cash?.id ?? null}
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col xxl={3} md={6}>
            <div className="mb-3">
              <Select
                label="Онлайн"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );

                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.online", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.online", null);
                  }
                }}
                value={
                  form?.relationModule?.options?.payment?.online?.id ?? null
                }
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
          <Col xxl={3} md={6}>
            <div className="mb-3">
              <Select
                label="Баллы"
                placeholder="Выберите тип"
                className="me-3"
                onClick={(e) => {
                  let info = form?.relationModule?.options?.typesPayment.find(
                    (item) => item.id === e.value
                  );
                  if (info?.id && info?.paymentTypeKind) {
                    setValue("relationModule.options.payment.point", {
                      id: info.id,
                      paymentTypeKind: info.paymentTypeKind,
                    });
                  } else {
                    setValue("relationModule.options.payment.point", null);
                  }
                }}
                value={
                  form?.relationModule?.options?.payment?.point?.id ?? null
                }
                data={
                  form?.relationModule?.options?.typesPayment?.length > 0
                    ? [
                        { title: "Не выбрано", value: null },
                        ...form.relationModule.options.typesPayment.map(
                          (e) => ({
                            title: e.name,
                            value: e.id,
                          })
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Col>
        </Row>

        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h5 className="fw-7">Синхронизация</h5>

        <Form.Check
          id="mode"
          label="Ручной режим"
          type="switch"
          className="fs-09"
          {...register("relationModule.options.mode")}
        />
      </div>
      {form?.relationModule?.options?.mode ? (
        <>
          <Row>
            <Col xxl={4} md={6}>
              <Card body className="mb-4">
                {form?.relationModule &&
                  (!form.relationModule?.options?.organizations ||
                    form.relationModule?.options.organizations?.length ===
                      0) && (
                    <div className="blur-text">
                      <h5 className="fw-7 mb-2">Категории</h5>
                      <p className="fs-09">
                        Сначала выгрузите
                        <br />
                        организации
                      </p>
                    </div>
                  )}
                <div
                  className={
                    form?.relationModule &&
                    (!form.relationModule?.options?.organizations ||
                      form.relationModule?.options?.organizations?.length === 0)
                      ? "blur"
                      : ""
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Категории</h5>
                    <Button
                      disabled={
                        !form?.relationModule?.options?.login ||
                        form?.relationModule?.options?.status?.category
                          ?.status == "process"
                      }
                      onClick={() => clickGetErpCategory()}
                      className="btn btn-sm btn-default"
                    >
                      {!form?.relationModule?.options?.status?.category
                        ?.status ||
                      form?.relationModule?.options?.status?.category?.status ==
                        "end" ? (
                        <IoRefreshOutline size={22} className="text-gray" />
                      ) : (
                        form?.relationModule?.options?.status?.category?.text ??
                        "Выгрузка данных..."
                      )}
                    </Button>
                  </div>
                  <h2 className="m-0 d-flex align-items-center">
                    {statistic.categories}
                  </h2>
                  <div className="mt-2">
                    <Link
                      to="/catalog/categories"
                      disabled={!form?.relationModule?.options?.login}
                      className="btn-sm btn-primary-outline"
                    >
                      Список
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={4} md={6}>
              <Card body className="mb-4">
                {statistic.categories === 0 && (
                  <div className="blur-text">
                    <h5 className="fw-7 mb-2">Товары</h5>
                    <p className="fs-09">
                      Сначала выгрузите
                      <br />
                      категории
                    </p>
                  </div>
                )}
                <div className={statistic.categories === 0 ? "blur" : ""}>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Товары</h5>
                    <div className="d-flex align-items-center justify-content-between">
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.product
                            ?.status == "process"
                        }
                        onClick={() => setSettings(true)}
                        className="btn btn-sm btn-default"
                      >
                        {!form?.relationModule?.options?.status?.product
                          ?.status ||
                        form?.relationModule?.options?.status?.product
                          ?.status == "end" ? (
                          <IoRefreshOutline size={20} className="text-gray" />
                        ) : (
                          form?.relationModule?.options?.status?.product
                            ?.text ?? "Выгрузка данных..."
                        )}
                      </Button>
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.product
                            ?.status == "process"
                        }
                        onClick={() => {
                          openWindow({
                            json: false,
                            title: "Логи товаров",
                            data: form.relationModule.log.product.data.trim(),
                            header:
                              form.relationModule.log.product.header.trim(),
                            body: `<h1>Логи товаров${
                              form.relationModule.log?.product?.datetime
                                ? " за " +
                                  moment(
                                    form.relationModule.log.product.datetime
                                  ).format("DD.MM.YYYY kk:mm:ss")
                                : ""
                            }</h1><div>
                        <h3>Статус</h3>
                        <b>
                          ${form.relationModule.log?.product?.status ?? "200"}
                        </b>
                        <h3>Отправлено</h3>
                        <pre class="code" id="header"></pre>
                        <h3>Ответ</h3>
                        <pre class="code" id="data"></pre>
                      </div>`,
                          });
                        }}
                        className="btn ms-2 btn-sm btn-default"
                      >
                        <IoTerminalOutline size={18} />
                      </Button>
                    </div>
                  </div>
                  <h2 className="m-0 d-flex align-items-center">
                    {statistic.products}
                  </h2>
                  <div className="mt-2 d-flex flex-column">
                    <Link
                      to="/catalog/products"
                      disabled={!form?.relationModule?.options?.login}
                      className="btn-sm btn-primary-outline"
                    >
                      Список
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={4} md={6}>
              <Card body className="mb-4">
                {statistic.products === 0 && (
                  <div className="blur-text">
                    <h5 className="fw-7 mb-2">Модификаторы</h5>
                    <p className="fs-09">
                      Сначала выгрузите
                      <br />
                      товары
                    </p>
                  </div>
                )}
                <div className={statistic.products === 0 ? "blur" : ""}>
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Модификаторы</h5>
                    <Button
                      disabled={
                        !form?.relationModule?.options?.login ||
                        form?.relationModule?.options?.status?.modifier
                          ?.status == "process"
                      }
                      onClick={() => clickGetErpModifier()}
                      className="btn btn-sm btn-default"
                    >
                      {!form?.relationModule?.options?.status?.modifier
                        ?.status ||
                      form?.relationModule?.options?.status?.modifier?.status ==
                        "end" ? (
                        <IoRefreshOutline size={22} className="text-gray" />
                      ) : (
                        form?.relationModule?.options?.status?.modifier?.text ??
                        "Выгрузка данных..."
                      )}
                    </Button>
                  </div>
                  <h2 className="m-0 d-flex align-items-center">
                    {statistic.modifiers}
                  </h2>
                  <div className="mt-2">
                    <Link
                      to="/catalog/modifiers"
                      disabled={!form?.relationModule?.options?.login}
                      className="btn-sm btn-primary-outline"
                    >
                      Список
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xxl={4} md={6}>
              <Card body className="mb-4">
                {form?.relationModule &&
                  (!form.relationModule?.options?.organizations ||
                    form.relationModule?.options.organizations?.length ===
                      0) && (
                    <div className="blur-text">
                      <h5 className="fw-7 mb-2">Столы</h5>
                      <p className="fs-09">
                        Сначала выгрузите
                        <br />
                        организации
                      </p>
                    </div>
                  )}
                <div
                  className={
                    form?.relationModule &&
                    (!form.relationModule?.options?.organizations ||
                      form.relationModule?.options?.organizations?.length === 0)
                      ? "blur"
                      : ""
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Столы</h5>
                    <Button
                      disabled={
                        !form?.relationModule?.options?.login ||
                        form?.relationModule?.options?.status?.table?.status ==
                          "process"
                      }
                      onClick={() => clickGetErpTable()}
                      className="btn btn-sm btn-default"
                    >
                      {!form?.relationModule?.options?.status?.table?.status ||
                      form?.relationModule?.options?.status?.table?.status ==
                        "end" ? (
                        <IoRefreshOutline size={22} className="text-gray" />
                      ) : (
                        form?.relationModule?.options?.status?.table?.text ??
                        "Выгрузка данных..."
                      )}
                    </Button>
                  </div>
                  <h2 className="m-0 d-flex align-items-center">
                    {statistic.tables}
                  </h2>
                  <div className="mt-2">
                    <Link
                      to="/options/affiliates/tables"
                      disabled={!form?.relationModule?.options?.login}
                      className="btn-sm btn-primary-outline"
                    >
                      Список
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
            {Number(statistic?.additions) > 0 && (
              <Col xxl={4} md={6}>
                <Card body className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Добавки</h5>
                  </div>
                  <h2 className="m-0 d-flex align-items-center">
                    {statistic.additions}
                  </h2>
                  <div className="mt-2">
                    <Link
                      to="/catalog/additions"
                      className="btn-sm btn-primary-outline"
                    >
                      Список
                    </Link>
                  </div>
                </Card>
              </Col>
            )}
            <Col xxl={4} md={6}>
              <Card body className="mb-4">
                {form?.relationModule &&
                  (statistic.products === 0 || statistic.categories === 0) && (
                    <div className="blur-text">
                      <h5 className="fw-7 mb-2">Стоп лист</h5>
                      <p className="fs-09">
                        Сначала выгрузите
                        <br />
                        категории и товары
                      </p>
                    </div>
                  )}
                <div
                  className={
                    statistic.products === 0 || statistic.categories === 0
                      ? "blur"
                      : ""
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2 fw-6 h6">Стоп лист</h5>
                    <Button
                      disabled={
                        !form?.relationModule?.options?.login ||
                        form?.relationModule?.options?.status?.stoplist
                          ?.status == "process"
                      }
                      onClick={() => clickGetErpStopList()}
                      className="btn btn-sm btn-default"
                    >
                      {!form?.relationModule?.options?.status?.stoplist
                        ?.status ||
                      form?.relationModule?.options?.status?.stoplist?.status ==
                        "end" ? (
                        <IoRefreshOutline size={22} className="text-gray" />
                      ) : (
                        form?.relationModule?.options?.status?.stoplist?.text ??
                        "Выгрузка данных..."
                      )}
                    </Button>
                  </div>
                  <h2 className="m-0 d-flex align-items-center">
                    {form?.relationModule?.options?.stoplistCount ?? 0}
                  </h2>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xxl={6} md={6}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h5 className="mb-2 fw-6 h6">Уведомления (callback)</h5>
                </div>
                <div className="mb-3">
                  <Input
                    label="Статусы заказа"
                    readOnly={false}
                    defaultValue={`${process.env.REACT_APP_WEBHOOK_URL}/accounting`}
                  />
                </div>
                <Input
                  label="Стоп лист"
                  readOnly={false}
                  defaultValue={`${process.env.REACT_APP_WEBHOOK_URL}/stoplist`}
                />
                <p className="text-muted fs-07 mt-1">
                  Данные ссылки нужно указать в настройках интеграции.
                </p>
              </Card>
            </Col>
          </Row>

          <h5 className="my-4 fw-7">Данные для заказа</h5>
          <Row>
            <Col xxl={4} md={6}>
              <Row>
                <Col md={12}>
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Организации</h5>
                      <div className="d-flex align-items-center justify-content-between">
                        <Button
                          disabled={
                            !form?.relationModule?.options?.login ||
                            form?.relationModule?.options?.status?.organization
                              ?.status == "process"
                          }
                          onClick={() => clickGetErpOrganization()}
                          className="btn btn-sm btn-default"
                        >
                          {!form?.relationModule?.options?.status?.organization
                            ?.status ||
                          form?.relationModule?.options?.status?.organization
                            ?.status == "end" ? (
                            <IoRefreshOutline size={22} className="text-gray" />
                          ) : (
                            form?.relationModule?.options?.status?.organization
                              ?.text ?? "Выгрузка данных..."
                          )}
                        </Button>
                        <Button
                          disabled={
                            !form?.relationModule?.options?.login ||
                            form?.relationModule?.options?.status?.organization
                              ?.status == "process"
                          }
                          onClick={() => {
                            openWindow({
                              title: "Логи организаций",
                              data: form?.relationModule?.options
                                ?.organizations,
                              body: `<h1>Логи организаций</h1><div>
                          <h3>Ответ</h3>
                          <pre class="code" id="data"></pre>
                        </div>`,
                            });
                          }}
                          className="btn ms-2 btn-sm btn-default"
                        >
                          <IoTerminalOutline size={18} />
                        </Button>
                      </div>
                    </div>
                    <h2 className="mb-2 d-flex align-items-center">
                      {form?.relationModule &&
                      Array.isArray(
                        form?.relationModule?.options?.organizations
                      )
                        ? form.relationModule.options.organizations.length
                        : 0}
                    </h2>
                    <div className="d-flex align-items-center mb-2">
                      <Form.Check.Input
                        type="checkbox"
                        className="btn-sm"
                        id="organization-all"
                        onClick={(e) => {
                          let newArray =
                            form.relationModule.options.organizations.map(
                              (item) => ({ ...item, main: e.target.checked })
                            );

                          setValue(
                            "relationModule.options.organizations",
                            newArray
                          );
                        }}
                      />
                      <Form.Check.Label
                        htmlFor="organization-all"
                        className="ms-2 fs-09 fw-7"
                      >
                        Выбрать все
                      </Form.Check.Label>
                    </div>
                    <div className="body-scroll">
                      {form?.relationModule &&
                        Array.isArray(
                          form?.relationModule?.options?.organizations
                        ) &&
                        form?.relationModule?.options?.organizations &&
                        form?.relationModule?.options?.organizations.map(
                          (item, index) => (
                            <>
                              <div className="my-2 d-flex align-items-center">
                                <div>
                                  <Form.Check.Input
                                    type="checkbox"
                                    name="main"
                                    id={"main-org-" + item.id}
                                    className="btn-sm"
                                    checked={item?.main}
                                    onClick={() => {
                                      form.relationModule.options.organizations[
                                        index
                                      ].main = !!!item.main;

                                      setValue(
                                        "relationModule.options.organizations",
                                        form.relationModule.options
                                          .organizations
                                      );
                                    }}
                                  />
                                </div>
                                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                  <label
                                    for={"main-org-" + item.id}
                                    className="fs-09 cursor-pointer"
                                  >
                                    <span className="fw-6">{item.name}</span>
                                    {item?.orderServiceType && (
                                      <span>{item.orderServiceType}</span>
                                    )}
                                  </label>

                                  <OverlayTrigger
                                    trigger={["focus", "click"]}
                                    className="ms-2"
                                    rootClose
                                    key="top"
                                    placement="top"
                                    overlay={
                                      <Popover id="popover-positioned-top">
                                        <Popover.Body className="white-space">
                                          {item.id}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a>
                                      <IoInformationCircleOutline
                                        size={22}
                                        className="text-muted"
                                      />
                                    </a>
                                  </OverlayTrigger>
                                </div>
                              </div>
                              {/* <div className="ms-4 d-flex flex-row align-items-center mb-3">
                                <Form.Check.Input
                                  type="checkbox"
                                  name="main"
                                  id={"main-org-all-" + item.id}
                                  className="btn-sm"
                                  defaultChecked={item?.priceAllOrganization}
                                  onClick={() => {
                                    form.relationModule.options.organizations[
                                      index
                                    ].priceAllOrganization =
                                      !!!item.priceAllOrganization;

                                    setValue(
                                      "relationModule.options.organizations",
                                      form.relationModule.options.organizations
                                    );
                                  }}
                                />
                                <label
                                  for={"main-org-all-" + item.id}
                                  className="fs-07 ms-2 text-muted cursor-pointer"
                                >
                                  Выгрузка всех цен по организациям
                                </label>
                              </div> */}
                            </>
                          )
                        )}
                    </div>
                  </Card>
                </Col>
                <Col md={12}>
                  {form?.relationModule?.options?.type === "web" && (
                    <Card body className="mb-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-2 fw-6 h6">Внешнее меню</h5>
                        <div className="d-flex align-items-center justify-content-between">
                          <Button
                            disabled={
                              !form?.relationModule?.options?.login ||
                              form?.relationModule?.options?.status?.menu
                                ?.status == "process"
                            }
                            onClick={() => clickGetErpMenu()}
                            className="btn btn-sm btn-default"
                          >
                            {!form?.relationModule?.options?.status?.menu
                              ?.status ||
                            form?.relationModule?.options?.status?.menu
                              ?.status == "end" ? (
                              <IoRefreshOutline
                                size={22}
                                className="text-gray"
                              />
                            ) : (
                              form?.relationModule?.options?.status?.menu
                                ?.text ?? "Выгрузка данных..."
                            )}
                          </Button>
                          <Button
                            disabled={
                              !form?.relationModule?.options?.login ||
                              form?.relationModule?.options?.status?.menu
                                ?.status == "process"
                            }
                            onClick={() => {
                              openWindow({
                                title: "Логи внешнего меню",
                                data: form?.relationModule?.options?.menus,
                                body: `<h1>Логи внешнего меню</h1><div>
                        <h3>Ответ</h3>
                        <pre class="code" id="data"></pre>
                      </div>`,
                              });
                            }}
                            className="btn ms-2 btn-sm btn-default"
                          >
                            <IoTerminalOutline size={18} />
                          </Button>
                        </div>
                      </div>

                      <h2 className="m-0 d-flex align-items-center">
                        {form?.relationModule &&
                        Array.isArray(form?.relationModule?.options?.menus)
                          ? form.relationModule.options.menus.length
                          : 0}
                      </h2>
                      <div className="body-scroll">
                        <h5 className="mb-3 mt-3 fw-6 h6">Категории</h5>
                        {form?.relationModule &&
                        Array.isArray(
                          form?.relationModule?.options?.menuCategories
                        ) &&
                        form?.relationModule?.options?.menuCategories?.length >
                          0 ? (
                          form?.relationModule?.options?.menuCategories.map(
                            (item, index) => (
                              <div className="mt-2 d-flex align-items-center">
                                <div className="me-2">
                                  <Form.Check.Input
                                    type="radio"
                                    name="category_main"
                                    id="main-category"
                                    className="btn-sm"
                                    defaultChecked={item?.main}
                                    onClick={() => {
                                      form.relationModule.options.menuCategories =
                                        form.relationModule.options.menuCategories.map(
                                          (e) => {
                                            if (e.id == item.id) {
                                              e.main = !!!item.main;
                                            } else {
                                              e.main = false;
                                            }
                                            return e;
                                          }
                                        );
                                      setValue(
                                        "relationModule.options.menuCategories",
                                        form.relationModule.options
                                          .menuCategories
                                      );
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <div className="fs-09">{item.name}</div>
                                  <OverlayTrigger
                                    trigger={["focus", "click"]}
                                    rootClose
                                    className="ms-2"
                                    key="top"
                                    placement="top"
                                    overlay={
                                      <Popover id="popover-positioned-top">
                                        <Popover.Body className="white-space">
                                          {item.id}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a>
                                      <IoInformationCircleOutline
                                        size={22}
                                        className="text-muted"
                                      />
                                    </a>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <span className="text-muted fs-09">Нет данных</span>
                        )}
                        <h5 className="mb-3 mt-4 fw-6 h6">Меню</h5>
                        {form?.relationModule &&
                        Array.isArray(form?.relationModule?.options?.menus) &&
                        form?.relationModule?.options?.menus?.length > 0 ? (
                          form?.relationModule?.options?.menus.map(
                            (item, index) => (
                              <div className="mt-2 d-flex align-items-center">
                                <div className="me-2">
                                  <Form.Check.Input
                                    type="radio"
                                    name="menu_main"
                                    className="btn-sm"
                                    id="main-menu"
                                    defaultChecked={item?.main}
                                    onClick={() => {
                                      form.relationModule.options.menus =
                                        form.relationModule.options.menus.map(
                                          (e) => {
                                            if (e.id == item.id) {
                                              e.main = !!!item.main;
                                            } else {
                                              e.main = false;
                                            }
                                            return e;
                                          }
                                        );
                                      setValue(
                                        "relationModule.options.menus",
                                        form.relationModule.options.menus
                                      );
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <div className="fs-09">{item.name}</div>
                                  <OverlayTrigger
                                    trigger={["focus", "click"]}
                                    rootClose
                                    className="ms-2"
                                    key="top"
                                    placement="top"
                                    overlay={
                                      <Popover id="popover-positioned-top">
                                        <Popover.Body className="white-space">
                                          {item.id}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <a>
                                      <IoInformationCircleOutline
                                        size={22}
                                        className="text-muted"
                                      />
                                    </a>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <span className="text-muted fs-09">Нет данных</span>
                        )}
                      </div>
                    </Card>
                  )}
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Купоны</h5>
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.coupon
                            ?.status == "process"
                        }
                        onClick={() => clickGetErpCoupon()}
                        className="btn btn-sm btn-default"
                      >
                        {!form?.relationModule?.options?.status?.coupon
                          ?.status ||
                        form?.relationModule?.options?.status?.coupon?.status ==
                          "end" ? (
                          <IoRefreshOutline size={22} className="text-gray" />
                        ) : (
                          form?.relationModule?.options?.status?.coupon?.text ??
                          "Выгрузка данных..."
                        )}
                      </Button>
                    </div>
                    <h2 className="m-0 d-flex align-items-center">
                      {form?.relationModule &&
                      Array.isArray(form?.relationModule?.options?.coupons)
                        ? form.relationModule.options.coupons.length
                        : 0}
                    </h2>
                    <div className="body-scroll">
                      {form?.relationModule &&
                        Array.isArray(form?.relationModule?.options?.coupons) &&
                        form?.relationModule?.options?.coupons &&
                        form?.relationModule?.options?.coupons.map((item) => (
                          <div className="my-2 d-flex justify-content-between align-items-center">
                            <div className="fs-09 d-flex justify-content-between w-100">
                              <div>{item.number}</div>
                            </div>
                            <div>
                              <OverlayTrigger
                                trigger={["focus", "click"]}
                                rootClose
                                className="ms-2"
                                key="top"
                                placement="top"
                                overlay={
                                  <Popover id="popover-positioned-top">
                                    <Popover.Body className="white-space">
                                      <p>{item.id}</p>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <a>
                                  <IoInformationCircleOutline
                                    size={22}
                                    className="text-muted"
                                  />
                                </a>
                              </OverlayTrigger>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xxl={4} md={6}>
              <Row>
                <Col md={12}>
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Типы оплаты</h5>
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.payment
                            ?.status == "process"
                        }
                        onClick={() => clickGetErpTypesPayment()}
                        className="btn btn-sm btn-default"
                      >
                        {!form?.relationModule?.options?.status?.payment
                          ?.status ||
                        form?.relationModule?.options?.status?.payment
                          ?.status == "end" ? (
                          <IoRefreshOutline size={22} className="text-gray" />
                        ) : (
                          form?.relationModule?.options?.status?.payment
                            ?.text ?? "Выгрузка данных..."
                        )}
                      </Button>
                    </div>
                    <h2 className="mb-2 d-flex align-items-center">
                      {form?.relationModule?.options?.typesPayment?.length ?? 0}
                    </h2>
                    <div className="body-scroll">
                      {form?.relationModule?.options?.typesPayment &&
                        form?.relationModule?.options?.typesPayment.map(
                          (item) => (
                            <div className="my-2 d-flex justify-content-between align-items-center">
                              <div className="fs-09 d-flex justify-content-between w-100">
                                <div>{item.name}</div>
                                <div className="me-2 fw-6">{item.code}</div>
                              </div>
                              <div>
                                <OverlayTrigger
                                  trigger={["focus", "click"]}
                                  rootClose
                                  className="ms-2"
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Popover id="popover-positioned-top">
                                      <Popover.Body className="white-space">
                                        <p>{item.id}</p>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <a>
                                    <IoInformationCircleOutline
                                      size={22}
                                      className="text-muted"
                                    />
                                  </a>
                                </OverlayTrigger>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Терминалы</h5>

                      <div className="d-flex align-items-center justify-content-between">
                        <Button
                          disabled={
                            !form?.relationModule?.options?.login ||
                            form?.relationModule?.options?.status?.terminal
                              ?.status == "process"
                          }
                          onClick={() => clickGetErpTerminal()}
                          className="btn btn-sm btn-default"
                        >
                          {!form?.relationModule?.options?.status?.terminal
                            ?.status ||
                          form?.relationModule?.options?.status?.terminal
                            ?.status == "end" ? (
                            <IoRefreshOutline size={22} className="text-gray" />
                          ) : (
                            form?.relationModule?.options?.status?.terminal
                              ?.text ?? "Выгрузка данных..."
                          )}
                        </Button>
                        <Button
                          disabled={
                            !form?.relationModule?.options?.login ||
                            form?.relationModule?.options?.status?.terminal
                              ?.status == "process"
                          }
                          onClick={() => {
                            openWindow({
                              title: "Логи терминалов",
                              data: form?.relationModule?.options?.terminals,
                              body: `<h1>Логи терминалов</h1><div>
                        <h3>Ответ</h3>
                        <pre class="code" id="data"></pre>
                      </div>`,
                            });
                          }}
                          className="btn ms-2 btn-sm btn-default"
                        >
                          <IoTerminalOutline size={18} />
                        </Button>
                      </div>
                    </div>
                    <h2 className="mb-2 d-flex align-items-center">
                      {Array.isArray(form?.relationModule?.options?.terminals)
                        ? form.relationModule.options.terminals.reduce(
                            (total, item) => {
                              return (
                                total +
                                (Array.isArray(item?.items) &&
                                item?.items?.length > 0
                                  ? item.items.length
                                  : 0)
                              );
                            },
                            0
                          )
                        : 0}
                    </h2>
                    <div className="body-scroll">
                      {Array.isArray(
                        form?.relationModule?.options?.terminals
                      ) &&
                        form?.relationModule?.options?.terminals?.length > 0 &&
                        form.relationModule.options.terminals.map((item) => {
                          return (
                            <>
                              {item?.items?.length > 0 &&
                                item.items.map((item) => (
                                  <div className="d-flex my-2  justify-content-between align-items-center">
                                    <div>
                                      <p className="fs-09 d-flex justify-content-between">
                                        <span>{item.name}</span>
                                        <span>{item.address}</span>
                                      </p>
                                    </div>
                                    <div>
                                      <OverlayTrigger
                                        trigger={["focus", "click"]}
                                        rootClose
                                        className="ms-2"
                                        key="top"
                                        placement="top"
                                        overlay={
                                          <Popover id="popover-positioned-top">
                                            <Popover.Body className="white-space">
                                              <p className="mb-2">
                                                <p className="fw-6">
                                                  Организация
                                                </p>
                                                {item.organizationId}
                                              </p>
                                              <p>
                                                <p className="fw-6">Терминал</p>
                                                {item.id}
                                              </p>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <a>
                                          <IoInformationCircleOutline
                                            size={22}
                                            className="text-muted"
                                          />
                                        </a>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                ))}
                            </>
                          );
                        })}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xxl={4} md={6}>
              <Row>
                <Col md={12}>
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Скидки</h5>
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.discount
                            ?.status == "process"
                        }
                        onClick={() => clickGetErpDiscount()}
                        className="btn btn-sm btn-default"
                      >
                        {!form?.relationModule?.options?.status?.discount
                          ?.status ||
                        form?.relationModule?.options?.status?.discount
                          ?.status == "end" ? (
                          <IoRefreshOutline size={22} className="text-gray" />
                        ) : (
                          form?.relationModule?.options?.status?.discount
                            ?.text ?? "Выгрузка данных..."
                        )}
                      </Button>
                    </div>
                    <h2 className="mb-2 d-flex align-items-center">
                      {form?.relationModule &&
                      Array.isArray(form?.relationModule?.options?.discounts)
                        ? form.relationModule.options.discounts.length
                        : 0}
                    </h2>
                    <div className="body-scroll">
                      {form?.relationModule &&
                        Array.isArray(
                          form?.relationModule?.options?.discounts
                        ) &&
                        form?.relationModule?.options?.discounts &&
                        form?.relationModule?.options?.discounts.map((item) => (
                          <div className="my-2 d-flex justify-content-between align-items-center">
                            <div>
                              <p className="fs-09 d-flex justify-content-between">
                                <span>{item.name}</span>
                                <span>{item.orderServiceType}</span>
                              </p>
                            </div>
                            <div>
                              <OverlayTrigger
                                trigger={["focus", "click"]}
                                rootClose
                                className="ms-2"
                                key="top"
                                placement="top"
                                overlay={
                                  <Popover id="popover-positioned-top">
                                    <Popover.Body className="white-space">
                                      <p>{item.id}</p>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <a>
                                  <IoInformationCircleOutline
                                    size={22}
                                    className="text-muted"
                                  />
                                </a>
                              </OverlayTrigger>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Программы лояльности</h5>
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.promo
                            ?.status == "process"
                        }
                        onClick={() => clickGetErpPromo()}
                        className="btn btn-sm btn-default"
                      >
                        {!form?.relationModule?.options?.status?.promo
                          ?.status ||
                        form?.relationModule?.options?.status?.promo?.status ==
                          "end" ? (
                          <IoRefreshOutline size={22} className="text-gray" />
                        ) : (
                          form?.relationModule?.options?.status?.promo?.text ??
                          "Выгрузка данных..."
                        )}
                      </Button>
                    </div>
                    <h2 className="mb-2 d-flex align-items-center">
                      {form?.relationModule &&
                      Array.isArray(form?.relationModule?.options?.promos)
                        ? form.relationModule.options.promos.length
                        : 0}
                    </h2>
                    <div className="body-scroll">
                      {form?.relationModule &&
                        Array.isArray(form?.relationModule?.options?.promos) &&
                        form?.relationModule?.options?.promos &&
                        form?.relationModule?.options?.promos.map((item) => (
                          <div className="my-3 d-flex justify-content-between align-items-start">
                            <div>
                              <p className="fs-09 fw-6 d-flex justify-content-between">
                                <span>{item.name}</span>
                                <span>{item.orderServiceType}</span>
                              </p>
                              {item.marketingCampaigns?.length > 0 &&
                                item.marketingCampaigns.map((e, index) => (
                                  <p className="fs-08 mt-2 d-flex align-items-start">
                                    <div>
                                      <div
                                        className={
                                          "badge badge-fix-min me-2 " +
                                          (e.isActive
                                            ? "bg-badge-success"
                                            : "bg-badge-danger")
                                        }
                                      />
                                    </div>
                                    <span>
                                      {index + 1}. {e.name.trim()}
                                      {e.description?.length > 0 &&
                                        e.name.trim() !=
                                          e.description.trim() && (
                                          <p className="mt-1 text-muted fs-08">
                                            {e.description}
                                          </p>
                                        )}
                                    </span>
                                  </p>
                                ))}
                            </div>
                            <div>
                              <OverlayTrigger
                                trigger={["focus", "click"]}
                                rootClose
                                className="ms-2"
                                key="top"
                                placement="top"
                                overlay={
                                  <Popover id="popover-positioned-top">
                                    <Popover.Body className="white-space">
                                      <p>{item.id}</p>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <a>
                                  <IoInformationCircleOutline
                                    size={22}
                                    className="text-muted"
                                  />
                                </a>
                              </OverlayTrigger>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Card>
                </Col>
                <Col md={12}>
                  <Card body className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-2 fw-6 h6">Типы доставки</h5>
                      <Button
                        disabled={
                          !form?.relationModule?.options?.login ||
                          form?.relationModule?.options?.status?.delivery
                            ?.status == "process"
                        }
                        onClick={() => clickGetErpTypesDelivery()}
                        className="btn btn-sm btn-default"
                      >
                        {!form?.relationModule?.options?.status?.delivery
                          ?.status ||
                        form?.relationModule?.options?.status?.delivery
                          ?.status == "end" ? (
                          <IoRefreshOutline size={22} className="text-gray" />
                        ) : (
                          form?.relationModule?.options?.status?.delivery
                            ?.text ?? "Выгрузка данных..."
                        )}
                      </Button>
                    </div>
                    <h2 className="mb-2 d-flex align-items-center">
                      {Array.isArray(
                        form?.relationModule?.options?.typesDelivery
                      ) &&
                      form?.relationModule?.options?.typesDelivery[0]?.items
                        ?.length > 0
                        ? form.relationModule.options.typesDelivery[0].items
                            ?.length
                        : 0}
                    </h2>
                    <div className="body-scroll">
                      {Array.isArray(
                        form?.relationModule?.options?.typesDelivery
                      ) &&
                        form?.relationModule?.options?.typesDelivery[0]
                          ?.items &&
                        form?.relationModule?.options?.typesDelivery[0]?.items.map(
                          (item) => (
                            <div className="my-2 d-flex justify-content-between align-items-center">
                              <div className="fs-09 d-flex justify-content-between w-100">
                                <div className="me-2">{item.name}</div>
                                <div className="me-2 fw-6">
                                  {item.orderServiceType}
                                </div>
                              </div>
                              <div>
                                <OverlayTrigger
                                  trigger={["focus", "click"]}
                                  rootClose
                                  className="ms-2"
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Popover id="popover-positioned-top">
                                      <Popover.Body className="white-space">
                                        <p>{item.id}</p>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <a>
                                    <IoInformationCircleOutline
                                      size={22}
                                      className="text-muted"
                                    />
                                  </a>
                                </OverlayTrigger>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Card body className="mb-4">
            <h5 className="mb-4">Автономная выгрузка</h5>
            <Row>
              <Col>
                <div className="box-gray mb-3">
                  <p className="mb-2 fw-5">1. Настройте Iiko меню выгрузки</p>
                  <ol className="fs-09">
                    <li className="mb-1">Настройте организации и терминалы.</li>
                    <li className="mb-1">
                      Если у вас есть внешнее меню, настройте корректно их.
                    </li>
                    <li className="mb-1">
                      Добавьте все нужные категории и товары во внешнее меню
                      выгрузки Iiko.
                    </li>
                    <li className="mb-1">
                      После настройки нужно подождать до <b>30 мин</b>. Чтобы
                      данные сохранились на стороне Iiko.
                    </li>
                    <li>
                      <span className="text-danger">
                        Без настройки пунктов выше, выгрузка может быть
                        некорректной.
                      </span>
                      <br />
                      Если вам нужно выгрузить данные по отдельности, то можно
                      перейти в{" "}
                      <a
                        onClick={() =>
                          setValue("relationModule.options.mode", true)
                        }
                      >
                        <b>ручной режим</b>
                      </a>
                      .
                    </li>
                  </ol>
                </div>
                <p className="mb-2 fw-5">2. Выгрузите данные после настройки</p>
                <Button
                  disabled={true}
                  isLoading={all}
                  // disabled={all || !form?.relationModule?.options?.login}
                  onClick={() => clickGetErpAll()}
                  className="btn btn-primary ms-3 mb-3"
                >
                  <IoRefreshOutline size={18} className="me-2" />
                  Начать выгрузку
                </Button>
              </Col>
              <Col>
                <Row>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {form?.relationModule &&
                        Array.isArray(
                          form?.relationModule?.options?.organizations
                        )
                          ? form.relationModule.options.organizations.length
                          : 0}
                      </h2>
                      <p className="text-muted fs-09">Организации</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div
                      className="box-gray mb-4"
                      title="Только для Web интеграции"
                    >
                      <h2
                        className={
                          "m-0 d-flex align-items-center" +
                          (form?.relationModule?.options?.type != "web"
                            ? " text-muted"
                            : "")
                        }
                      >
                        {form?.relationModule &&
                        Array.isArray(form?.relationModule?.options?.menus)
                          ? form.relationModule.options.menus.length
                          : 0}
                      </h2>
                      <p className="text-muted fs-09">Внешнее меню</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {form?.relationModule &&
                        Array.isArray(form?.relationModule?.options?.terminals)
                          ? form.relationModule.options.terminals.length
                          : 0}
                      </h2>
                      <p className="text-muted fs-09">Терминалы</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {statistic.categories}
                      </h2>
                      <p className="text-muted fs-09">Категории</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {statistic.products}
                      </h2>
                      <p className="text-muted fs-09">Товары</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {statistic.modifiers}
                      </h2>
                      <p className="text-muted fs-09">Модификаторы</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {statistic.modifiers}
                      </h2>
                      <p className="text-muted fs-09">Столы</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {Array.isArray(
                          form?.relationModule?.options?.typesDelivery
                        ) &&
                        form?.relationModule?.options?.typesDelivery[0]?.items
                          ?.length > 0
                          ? form.relationModule.options.typesDelivery[0].items
                              ?.length
                          : 0}
                      </h2>
                      <p className="text-muted fs-09">Типы доставки</p>
                    </div>
                  </Col>
                  <Col xxl={4} md={6}>
                    <div className="box-gray mb-4">
                      <h2 className="m-0 d-flex align-items-center">
                        {form?.relationModule?.options?.typesPayment?.length ??
                          0}
                      </h2>
                      <p className="text-muted fs-09">Типы оплат</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </>
      )}
      <Offcanvas
        show={settings}
        onHide={setSettings}
        placement="end"
        name="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Настройки</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column justify-content-between">
          <div>
            <div className="mb-3">
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  id="product-all"
                  checked={
                    form?.productTitle &&
                    form?.productPrice &&
                    form?.productDesc &&
                    form?.productTitle &&
                    form?.productWeight &&
                    form?.productNutritional &&
                    form?.productCompound &&
                    form?.productImage
                  }
                  onChange={(e) => {
                    reset({
                      ...form,
                      productImage: e.target.checked,
                      productTitle: e.target.checked,
                      productDesc: e.target.checked,
                      productPrice: e.target.checked,
                      productCompound: e.target.checked,
                      productWeight: e.target.checked,
                      productNutritional: e.target.checked,
                    });
                  }}
                />
                <Form.Check.Label htmlFor="product-all" className="ms-2">
                  Выбрать все
                </Form.Check.Label>
              </Form.Check>
            </div>
            <div className="box box-gray mb-3">
              <h5 className="mb-3">Картинки</h5>
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  name="productImage"
                  id="product-image"
                  {...register("productImage")}
                />
                <Form.Check.Label
                  htmlFor="product-image"
                  className="ms-2 d-flex flex-column"
                >
                  Выгружать картинки
                  <p className="text-muted fs-07">
                    Все картинки будут удалены и заменены на новые
                  </p>
                </Form.Check.Label>
              </Form.Check>

              {form?.productImage && (
                <>
                  <Form.Check className="ms-4 mt-2">
                    <Form.Check.Input
                      type="checkbox"
                      name="productImageMain"
                      className="btn-sm"
                      id="product-image-main"
                      {...register("productImageMain")}
                    />
                    <Form.Check.Label
                      htmlFor="product-image-main"
                      className="ms-2 fs-09 d-flex flex-column"
                    >
                      Заменять только главную
                      <p className="text-muted fs-07">
                        Если у вас несколько картинок, будет заменена только
                        главная
                      </p>
                    </Form.Check.Label>
                  </Form.Check>
                </>
              )}
            </div>
            <div className="box box-gray">
              <h5 className="mb-3">Данные</h5>
              <Form.Check className="mb-3">
                <Form.Check.Input
                  type="checkbox"
                  name="productTitle"
                  id="product-title"
                  {...register("productTitle")}
                />
                <Form.Check.Label htmlFor="product-ttile" className="ms-2">
                  Название
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-3">
                <Form.Check.Input
                  type="checkbox"
                  name="productDesc"
                  id="product-desc"
                  {...register("productDesc")}
                />
                <Form.Check.Label htmlFor="product-desc" className="ms-2">
                  Описание
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-3">
                <Form.Check.Input
                  type="checkbox"
                  name="productPrice"
                  id="product-price"
                  {...register("productPrice")}
                />
                <Form.Check.Label htmlFor="product-price" className="ms-2">
                  Цена
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-3">
                <Form.Check.Input
                  type="checkbox"
                  name="productCompound"
                  id="product-compound"
                  {...register("productCompound")}
                />
                <Form.Check.Label htmlFor="product-compound" className="ms-2">
                  Состав
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="mb-3">
                <Form.Check.Input
                  type="checkbox"
                  name="productWeight"
                  id="product-weight"
                  {...register("productWeight")}
                />
                <Form.Check.Label htmlFor="product-weight" className="ms-2">
                  Вес
                </Form.Check.Label>
              </Form.Check>
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  name="productNutritional"
                  id="product-nutritional"
                  {...register("productNutritional")}
                />
                <Form.Check.Label
                  htmlFor="product-nutritional"
                  className="ms-2"
                >
                  Пищевая ценность (КБЖУ)
                </Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          <div>
            {statistic.products > 100 && (
              <p className="fs-08 text-center text-muted mb-2">
                Время выгрузки может занимать до{" "}
                {Math.round(
                  (statistic.products * 1.5 + statistic.products * 0.2) / 60
                )}{" "}
                мин
              </p>
            )}
            {!form?.productTitle &&
              !form?.productPrice &&
              !form?.productDesc &&
              !form?.productTitle &&
              !form?.productWeight &&
              !form?.productNutritional &&
              !form?.productCompound &&
              !form?.productImage && (
                <p className="text-danger fs-08 text-center mb-2">
                  Выберите хотя бы один параметр выгрузки
                </p>
              )}
            <Button
              disabled={
                (!form?.productTitle &&
                  !form?.productPrice &&
                  !form?.productDesc &&
                  !form?.productTitle &&
                  !form?.productWeight &&
                  !form?.productNutritional &&
                  !form?.productCompound &&
                  !form?.productImage) ||
                !form?.relationModule?.options?.login ||
                form?.relationModule?.options?.status?.product?.status ==
                  "process"
              }
              className="w-100 btn-primary"
              onClick={() => {
                setSettings(false);
                clickGetErpProduct();
              }}
            >
              {!form?.relationModule?.options?.status?.product?.status ||
              form?.relationModule?.options?.status?.product?.status == "end"
                ? "Начать выгрузку"
                : form?.relationModule?.options?.status?.product?.text ??
                  "Выгрузка данных..."}
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default IikoForm;
