import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isConnected: true,
  ip: null,
  apiId: null,
  brand: false,
  audio: true,
  source: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateApiId: (state, action) => {
      state.apiId = action.payload;
    },
    updateSource: (state, action) => {
      state.source = action.payload;
    },
    updateConnect: (state, action) => {
      state.isConnected = action.payload;
    },
    updateOptions: (state, action) => {
      state.options = action.payload;
    },
    updateIp: (state, action) => {
      state.ip = action.payload;
    },
    updateBrand: (state, action) => {
      state.brand = action.payload;
    },
    updateAudio: (state, action) => {
      state.audio = action.payload;
    },
  },
});

export const {
  updateSource,
  updateConnect,
  updateAudio,
  updateOptions,
  updateIp,
  updateBrand,
  updateApiId
} = settingsSlice.actions;

export default settingsSlice.reducer;
